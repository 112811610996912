.container {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: direction;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: direction;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  background-color: transparent;
}

.smcontainer {
  display: flex;
  flex-direction: direction;
  width: 100%;
}

.smcard {
  line-height: 0.255rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: direction;
  width: 100%;
}

.cardHeader {
  font-weight: bolder;
}

.cardBody {
  line-height: 1.25rem;
  margin-left: 1rem;
}

.cardText {
  font-family: "Helvetica", "Roboto", sans-serif;
  margin: 1rem;
  padding-left: 1rem;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.listCols {
  background-color: RGB(255, 255, 255, 60%);
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  width: 800px;
}

@media (min-width: 600px) {
  .listCols {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    width: 500px;
  }
}
@media (max-width: 600px) {
  .listCols {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    width: 500px;
  }
}
@media (min-width: 800px) {
  .listCols {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    width: 700px;
  }
}
@media (min-width: 1000px) {
  .listCols {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    width: 900px;
  }
}
:root {
  --time: 0.707s;
}

.cards {
  display: grid;
}

.card {
  perspective: 40rem;
}

.card-body {
  display: flex;
}

.card:hover .card-body, .card:focus .card-body {
  transform: rotateX(-3600deg);
}

.card-front, .card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-width: 100%;
}

.card-back {
  transform: rotateX(-180deg) translate(-100%, 0);
}

/* Make it Pretty */
body {
  line-height: 1.3;
  font-family: sans-serif;
}

.cards {
  grid-gap: 1.5rem;
  grid-auto-rows: 1fr;
  padding: 3rem;
  background: whitesmoke;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.card {
  display: flex;
  /* transition: z-index, transform calc(var(--time) / 4);
  transition-delay: var(--time), 0s; */
  text-decoration: none;
  color: inherit;
  z-index: 0;
}

.card:hover {
  /* transition-delay: 0s; */
  z-index: 1;
}

.card:active {
  /* transform: scale(0.975); */
}

.card-body {
  border-radius: 0.25rem;
  flex: 1;
}

.card-front, .card-back {
  display: flex;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  padding: 1.5rem;
  border-radius: 0.25rem;
}

.card-front {
  font-size: 1.5rem;
}

.shadow, .card-front, .card-back {
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
}


.work-experience {
  overflow-y: scroll;
  flex-grow: 1;
  margin-right: 20px;
}

.work-experience-slider {
  height: 200px;
}

/* Hide the scrollbar */
/* .work-experience::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.work-experience {
  -ms-overflow-style: none;  /* Internet Explorer 10+ 
  scrollbar-width: none;  /* Firefox 
} */

/* .resume {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
} */

h2 {
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

section {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

li h3 {
  margin: 0;
}

li p {
  margin: 5px 0;
}

.work-experience-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.work-experience-container {
  display: flex;
  align-items: center;
}

.work-experience {
  max-height: 351px;
  min-width: 800px;
  overflow-y: scroll;
  flex-grow: 1;
  margin-right: 20px;
}

.work-experience-slider {
  height: 200px;
}

/* Hide the scrollbar */
/* .work-experience::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.work-experience {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* scrollbar-width: none;  Firefox */
/* } */


