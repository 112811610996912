@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$pageMargin: 0px;


* {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.reactDiv .htmlDiv .jsDiv .reactDiv .nodeDiv {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  margin-left: 10rem;
}


.hero {
  height: 94vh;
  Background: url("/assets/images/homepageBackground.png") no-repeat left center/cover;
}

.hero-white {
  /*height: 94vh;*/
  Background: url("/assets/images/homepageBackground_white.png") no-repeat left center/cover fixed;
}

.reactLogo {
  animation: rotation 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.htmlLogo {
  animation-delay: 180s;
  animation: 2s;
  -webkit-animation: 2s;
  -moz-animation: 2s;
  -o-animation: 2s;
  -ms-animation: 2s;
}

.jsLogo {
  animation-delay: 120s;
  animation: 2s;
  -webkit-animation: 2s;
  -moz-animation: 2s;
  -o-animation: 2s;
  -ms-animation: 2s;
}

.cssLogo {
  animation-delay: 180s;
  animation: 5s;
  -webkit-animation: 5s;
  -moz-animation: 5s;
  -o-animation: 5s;
  -ms-animation: 5s;
}

.nodeLogo {
  animation-delay: 220s;
  animation: 5s;
  -webkit-animation: 5s;
  -moz-animation: 5s;
  -o-animation: 5s;
  -ms-animation: 5s;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  animation: animate 25s linear infinite;
  /* bottom: -450px; */
  top: 1010px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 9s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 3s;
  animation-duration: 0s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  animation-duration: 0s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 5s;
  animation-duration: 6s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 6s;
  animation-duration: 9s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 12s;
  animation-duration: 10s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  animation-duration: 14s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 16s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 85%;
  width: 15px;
  height: 15px;
  animation-delay: 18s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 20s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 10;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=home.css.map */